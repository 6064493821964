.App {
  text-align: center;
  background-color: #E5EBF0;
}

body {
  margin-top:-50px!important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

div#output {
  padding:50px;
}
#output.disabled a, button.disabled {
  background-color: rgba(128,128,128,0.75);
}
.disabled {
  pointer-events:none;
  background-color: rgba(128,128,128,0.75);
}
.enabled {
  pointer-events:auto;
}

li {
  list-style: none;
}

#output {
  padding:15px;
}

.flex {
  display:inline-block;
}
h3 {
  color: #053B6D;
  margin-top:0px;
}
.block {
  background-color:white;
  color: #00909a;
  text-align: center;
  margin: 50px;
  padding: 25px;
}

.red {
  color:white;
  background-color:#ff0068;
}
.red:hover {
  background-color:white;
  color:#ff0068;
}
.red-inverse {
  color:#ff0068;
  background-color:white;
}
.red-inverse:hover {
  background-color:#ff0068;
  color:white;
}
.tuerkis {
  color:white;
  background-color:#00a09a;
}
.tuerkis:hover, .tuerkis-hi {
  color:#00a09a;
  background-color: white;
}
.blau {
  color:white;
  background-color:#053b6d;
}
.blau:hover, .blau-hi {
  color:#053b6d;
  background-color: white;
}

.link {
  font-size:20px;
  font-weight:400;
  max-width:fit-content;
  padding:15px;
  border-radius: 5px;
  margin:5px;
  cursor: pointer;
  box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.5);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
div#debug {
  margin-top:50px;
  border: 1px solid red;
}
p {
  margin-top:50px;
  margin-bottom:50px;
  background-color:#E5EBF0;
}
button {
  padding:0;
  border:none;
}
span h3 {
  float:left;
}
input {
  font-size:30px;
  border-radius:5px;
}
div.reason_display {
  margin-top:25px;
  margin-bottom:25px;
  font-size:30px;
  background-color:#E5EBF0;
  padding:15px;
  width:fit-content;
  margin-left:auto;
  margin-right:auto;
  border-radius:5px;
}
div.block, div.output {
  border-radius:5px;
}

@media (max-width: 600px) {
  .block {
    margin:10px;
  }
  .flex {
    display:block;
  }
  h3 {
    font-size:20px;
  }
  input {
    font-size:20px;
  }
  div.reason_display {
    font-size:20px;
  }
}